<template>
  <div class="order-list-wrapper" v-loading="loading">
    <en-table-layout
      :tableData="pageData.data"
      @selection-change="orderListSelectionChange"
      :row-key="getRowKeys"
      ref="enTableLayout"
    >
      <template slot="header">
        <el-tabs type="card" @tab-click="setType" v-model="activeName">
          <el-tab-pane label="卡券订单列表" name="1" />
          <el-tab-pane label="卡券退单列表" name="2" />
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item label="订单创建时间">
          <el-date-picker
            style="width: 250px;"
            v-model="advancedForm.order_time_range"
            type="daterange"
            align="center"
            size="medium"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="激活状态" class="col-auto">
          <el-select
            style="width: 120px;"
            size="medium"
            v-model="advancedForm.status"
            placeholder="请选择"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option label="未激活" value="0"></el-option>
            <el-option label="已激活" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" label="禁用状态" class="col-auto">
          <el-select
            style="width: 120px;"
            size="medium"
            v-model="advancedForm.status"
            placeholder="请选择"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option label="未禁用" value="0"></el-option>
            <el-option label="已禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select
            style="width: 120px;"
            size="medium"
            v-model="advancedForm.trade_status"
            placeholder="请选择"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option label="未核销" value="0"></el-option>
            <el-option label="部分核销" value="1"></el-option>
            <el-option label="已核销" value="2"></el-option>
            <el-option label="已取消" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            size="medium"
            v-model="advancedForm.keywords"
            @input="advancedForm.keywords = advancedForm.keywords.replace(/\s+/g,'')"
            placeholder="请输入关键字进行搜索"
            clearable
          >
            <el-select
              slot="prepend"
              size="medium"
              v-model="advancedForm.type"
              placeholder="请选择类型"
              style="width: 130px"
            >
              <el-option label="销售单据编号" value="sell_trade_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
      </template>
      <template slot="toolbar_btn">
        <el-button
          @click="submitImport(0)"
          :loading="importLoading"
          size="small"
          type="primary"
        >导出全部</el-button>
        <el-button
          @click="submitImport(1)"
          :loading="importLoading1"
          size="small"
          type="primary"
        >导出勾选项</el-button>
      </template>
      <template slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column
          prop="sell_trade_sn"
          :label="activeName==='1'?'销售单据编号':'销售退单编号'"
          min-width="200"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="card_name"
          label="卡券名称"
          min-width="150"
          fixed="left"
          show-overflow-tooltip
        />
        <el-table-column prop="card_value" label="卡券面值" min-width="80" />
        <el-table-column prop="shop_name" label="所属商城" min-width="80" show-overflow-tooltip />
        <el-table-column prop="card_num" :label="activeName==='1'?'需开卡数量':'需退卡数量'" min-width="120" />
        <el-table-column v-if="activeName==='1'" prop="status" label="激活状态" min-width="80">
          <template slot-scope="{row}">
            <span v-if="row.status == 0">未激活</span>
            <span v-if="row.status == 2">已激活</span>
          </template>
        </el-table-column>
        <el-table-column v-if="activeName==='2'" prop="status" label="禁用状态" min-width="80">
          <template slot-scope="{row}">
            <span v-if="row.status == 0">未禁用</span>
            <span v-if="row.status == 2">已禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="client_name" label="客户名称" min-width="150" show-overflow-tooltip />
        <el-table-column prop="link_name" label="联系人名称" min-width="100" show-overflow-tooltip />
        <el-table-column prop="link_phone" label="联系电话" min-width="120" />
        <el-table-column prop="sell_name" label="销售人员名称" min-width="120" show-overflow-tooltip />
        <el-table-column prop="create_time" label="订单创建时间" min-width="150">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="trade_status" label="单据状态" min-width="80">
          <template slot-scope="{row}">
            <span v-if="row.trade_status == 0">未核销</span>
            <span v-if="row.trade_status == 1">部分核销</span>
            <span v-if="row.trade_status == 2">已核销</span>
            <span v-if="row.trade_status == 3">已取消</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleOperateOrder(scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_Login from "@/api/login";
import { handleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";

export default {
  name: "couponList",
  components: {
    EnTableLayout
  },
  data() {
    return {
      isOpen: false,
      activeName: "1",
      // 列表loading状态
      loading: false,
      // 导出loading状态
      importLoading: false,
      importLoading1: false,
      // 页面表格
      tableData: {},
      // 列表选中数据
      tempList: [],
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        shopType: 1
      },
      // 列表分页数据
      pageData: [],
      // 搜索数据
      advancedForm: {
        type: "sell_trade_sn",
        trade_status: "",
        status: ""
      }
    };
  },
  mounted() {
    if (sessionStorage.getItem("activeName")) {
      this.activeName = sessionStorage.getItem("activeName");
    }

    // 判断企业跳站点
    // if (sessionStorage.getItem("MixinIsFormEnterprise")) {
    //   this.MixinIsFormEnterprise = JSON.parse(
    //     sessionStorage.getItem("MixinIsFormEnterprise")
    //   );
    // }
    // API_Login.getLoginType().then(res => {
    //   if (res && res.parentLogin === 1) {
    //     this.MixinIsFormEnterprise = true;
    //     sessionStorage.setItem("MixinIsFormEnterprise", true);
    //   } else sessionStorage.setItem("MixinIsFormEnterprise", false);
    // });
    // console.log(this.MixinIsFormEnterprise);
    // this.params.shopType = this.MixinIsFormEnterprise ? 0 : 1;
    // 获取订单列表
    this.GET_OrderList(this.params);
  },
  destroyed() {
    sessionStorage.removeItem("activeName");
  },
  // activated() {
  // },
  // filters: {
  // },
  methods: {
    PermissionToCheck() {
      return API_order.getShopExtAuth().then(res => {
        if (res.open !== "OPEN") {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台"
          );
          setTimeout(() => {
            this.$store.getters.permission_routers.forEach(item => {
              if (item.path === "/order") {
                // 关闭菜单
                item.children.forEach((value, index) => {
                  if (value.path === "coupon-order-list") {
                    item.children.splice(index, 1);
                    this.$router.push("/order/order-lista");
                  }
                });
              }
            });
          }, 3000);
        } else {
          return true;
        }
      });
    },
    getRowKeys(row) {
      return row.id;
    },
    // 页签选择
    setType(el) {
      this.activeName = el.name;
      this.pageData.data = [];
      this.params = {
        page_no: 1,
        page_size: 20,
        shopType: this.activeName
      };
      this.tempList = [];
      this.advancedForm = {
        // 时间
        order_time_range: [],
        // 激活/禁用状态
        status: "",
        // 单据状态
        trade_status: "",
        // 关键字
        keywords: "",
        type: "sell_trade_sn"
      };
      this.$refs.enTableLayout.clearSelected();
      this.GET_OrderList(this.params);
    },
    // 选中数据
    orderListSelectionChange(list) {
      this.tempList = list;
    },
    // 查看详情
    handleOperateOrder(id) {
      this.PermissionToCheck().then(isRes => {
        if (!isRes) return;
        this.$router.push({
          path: `/order/coupon-order-detail/${id}?type=${this.activeName}`
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_OrderList(this.params);
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_OrderList(this.params);
    },
    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.params.page_no = 1;
      let params = {
        ...this.params,
        ...this.advancedForm
      };
      switch (this.advancedForm.type) {
        case "sell_trade_sn":
          params.sell_trade_sn = params.keywords;
          if (params.sell_trade_sn === "") {
            delete params.sell_trade_sn;
          }
          break;
        case "card_name":
          params.card_name = params.keywords;
          break;
        case "client_name":
          params.client_name = params.keywords;
          break;
        case "link_name":
          params.link_name = params.keywords;
          break;
        case "sell_name":
          params.sell_name = params.keywords;
          break;
        case "shop_name":
          params.shop_name = params.keywords;
          break;
        default:
          break;
      }
      if (this.advancedForm.order_time_range) {
        params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      for (const key in params) {
        if (params[key] === "") {
          delete params[key];
        }
      }
      this.GET_OrderList(params);
    },
    remove(arr, str) {
      var index = arr.indexOf(str);
      return arr.splice(index, 1);
    },
    //导出
    submitImport(type) {
      this.PermissionToCheck().then(isRes => {
        if (!isRes) return;
        let params = { ...this.params };
        if (type == 1) {
          params.ids = this.tempList.map(g => {
            return g.id;
          });
          if (!params.ids.length)
            return this.$message.error("请先选择要导出的数据");
        }
        if (type == 0) {
          this.importLoading = true;
        } else {
          this.importLoading1 = true;
        }
        let orderFunc = "getCardsOrderList";
        let filterVals = [
          "sell_trade_sn",
          "card_name",
          "card_value",
          "shop_name",
          "card_num",
          "status",
          "client_name",
          "link_name",
          "link_phone",
          "sell_name",
          "create_time",
          "trade_status"
        ];
        let tHeaders = [
          "销售单据编号",
          "销售退单编号",
          "卡券名称",
          "卡券面值",
          "所属商城",
          "需开卡数量",
          "需退卡数量",
          "激活状态",
          "禁用状态",
          "客户名称",
          "联系人名称",
          "联系电话",
          "销售人员名称",
          "订单创建时间",
          "单据状态"
        ];
        let templateName = "";
        switch (this.activeName) {
          case "1":
            templateName = "卡券订单列表";
            this.remove(tHeaders, "销售退单编号");
            this.remove(tHeaders, "需退卡数量");
            this.remove(tHeaders, "禁用状态");
            break;
          case "2":
            templateName = "卡券退单列表";
            this.remove(tHeaders, "销售单据编号");
            this.remove(tHeaders, "需开卡数量");
            this.remove(tHeaders, "激活状态");
            break;
          default:
            break;
        }
        delete params.page_no;
        delete params.page_size;
        API_order[orderFunc](params)
          .then(res => {
            res.data.map((item, index) => {
              item.create_time = Foundation.unixToDate(
                item.create_time,
                "yyyy-MM-dd hh:mm:ss"
              );
              switch (item.status) {
                case 0:
                  item.status = this.activeName === "1" ? "未激活" : "未禁用";
                  break;
                case 2:
                  item.status = this.activeName === "1" ? "已激活" : "已禁用";
                  break;
                default:
                  break;
              }
              switch (item.trade_status) {
                case 0:
                  item.trade_status = "未核销";
                  break;
                case 1:
                  item.trade_status = "部分核销";
                  break;
                case 2:
                  item.trade_status = "全部核销";
                  break;
                case 3:
                  item.trade_status = "取消(支付状态)";
                  break;
                default:
                  break;
              }
            });
            handleDownload(
              res.data,
              tHeaders,
              filterVals,
              "卡券订单列表",
              false
            );
            this.importLoading = false;
            this.importLoading1 = false;
          })
          .catch(res => {
            this.importLoading = false;
            this.importLoading1 = false;
          });
      });
    },
    GET_OrderList(params) {
      this.PermissionToCheck().then(isRes => {
        if (!isRes) return;
        this.loading = true;
        if (this.activeName === "1") {
          API_order.getCardsOrderList(params).then(res => {
            this.pageData = res;
          });
        } else if (this.activeName === "2") {
          API_order.getRefundOrderList(params).then(res => {
            this.pageData = res;
          });
        }
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0;
}
</style>
